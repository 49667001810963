import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import logo from '../../images/whitelogo.png'
import { useDispatch, useSelector } from "react-redux";
import { getProfiledata } from "../../Store/ProdataAction";

const ShareHeader = () => {

    const [show, setShow] = useState(false)

    const prodata = useSelector((state) => state.ProData.profiledata);

    const dispatch = useDispatch()

    const navigate = useNavigate()
    useEffect(() => {
        dispatch(getProfiledata())
    }, [])



    return (
        <header>
            <div class="container">
                <div class="header-data d-flex align-items-center justify-content-between">
                    <div class="logo">
                        <Link to='/' title=""><img src={logo} alt="" /></Link>
                    </div>
              
                    {/* <nav>
                        <ul>
                            <li>
                                <Link to="/" title="">
                                    <i class="las la-home d-block"></i>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to="/talentscore" title="">
                                    <i class="las la-chart-pie d-block"></i>
                                    Talent Score
                                </Link>

                            </li>
                            <li>
                                <Link to="/myclub" title="">
                                    <i class="las la-building d-block"></i>
                                    My Clubs
                                </Link>
                            </li>
                            <li>
                                <Link to="/offer" title="">
                                    <i class="las la-tag d-block"></i>
                                    Todays Offer
                                </Link>

                            </li>

                            <li>
                                <Link to="/bucketlist" title="" class="not-box-openm">
                                    <i class="las la-briefcase d-block"></i>
                                    My Bucket List
                                </Link>

                            </li>

                        </ul>
                    </nav> */}
                    <div className="d-flex">
                        <Link to={`/log`}><button className="btn btn-light mx-2">Login</button></Link>
                        <Link to={`/log`}><button className="btn btn-light mx-2">Register</button></Link>
                    </div>



           




                </div>
            </div>
        </header>
    )
}

export default ShareHeader
