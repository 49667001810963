import React, { useState } from 'react';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { GetQuestions } from '../../Store/QuestionActions';

import { Link } from 'react-router-dom';
export default function QuestionaireCatCard(props) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [id, setId] = useState(5);
  const [cid, setCid] = useState(11);



  return (
    <div style={{cursor :"pointer"}} onClick={() => dispatch(GetQuestions(props.id, props.cid))}>
      <Box sx={{ marginBottom: "1rem", }}>
        <Card sx={{ display: 'flex', justifyContent: "space-between", borderRadius: "5px", boxShadow: "rgba(99, 99, 99, 0.4) 0px 2px 8px 0px " }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: "space-between", width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
              <img src={props.image} alt="iconImage" style={{ width: "100%", height: '100%', color: "white", background: "#e73758", borderRadius: "5px" }} />
              <CardContent sx={{ whiteSpace: 'nowrap', padding: "0.5rem", display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center", margin: "auto 0" }}>
                <Typography variant="p" sx={{ whiteSpace: 'nowrap', padding: "0", fontWeight: "bold" }}>
                  {props.title}
                </Typography>
              </CardContent>
            </Box>
         
          </Box>
        </Card>
      </Box>
    </div>
  );

}
QuestionaireCatCard.defaultProps = {
  id: 5,
  cid: 11,
};