import React, { useState } from 'react'
import calsses from './ScoreSection.module.css';
import { useDispatch } from 'react-redux';
import { GetList } from '../../Store/QuestionActions';
import QuestionCategoriesList from './QuestionCatList';
import Questionaire from './Questionaire';
import { GetQuestions } from '../../Store/QuestionActions';
import { UseDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
const ScoreSection = () => {
  const [id, setId] = useState(5);
  const [Title, setTitle] = useState('Academic');
  const [Cid, setCid] = useState(1);
  const [Color, setColor] = useState('bg-yellow');
  const dispatch = useDispatch();
  const handleDataChange = (id, title, cid, color) => {
    setId(id);
    setTitle(title);
    setCid(cid);
    dispatch(GetQuestions(id, cid));
    setColor(color);
  }
  console.log(id, Cid)

  const marks = useSelector((state) => state.DashMarks);

  console.log(marks);
  return (
    <div className={calsses['main-section']}>
      <div className='container'>
        <div className={calsses['main-section-data']}>
          <div className={calsses['search-form']}>
            <div className={"col-xl-3 col-md-6 p-2"}>
              <div className={'card'}>
                <div className="card-block">
                  <div className='card-body'>

                    <div className="row">
                      <div className="col-8">
                        <h4>{marks.AverageScore}</h4>
                        <h6>All talent score</h6>
                      </div>
                      <div className="col-4 text-right">
                      </div>
                    </div>
                  </div>
                  <div className={`card-footer ${calsses['bg-red']}`} onClick={() => handleDataChange(5, "Academic", 1, '#f6d331')}>
                    <div className="row align-items-center">
                      <div className="col-9">
                        <span>Update</span>
                      </div>
                      <div className="col-3">
                        <EditIcon/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-xl-3 col-md-6 p-2"}>
              <div className={'card'}>
                <div className="card-block">
                  <div className='card-body'>
                    <div className="row">
                      <div className="col-8">
                        <h4>{marks.cat1}</h4>
                        <h6>Achievements</h6>
                      </div>
                      <div className="col-4 text-right">
                      </div>
                    </div>
                  </div>
                  <div className={`card-footer ${calsses['bg-yellow']}`} onClick={() => handleDataChange(5, "Academic", 1, '#f6d331')}>
                    <div className="row align-items-center">
                      <div className="col-9">
                        <span>Update</span>
                      </div>
                      <div className="col-3">
                      <EditIcon/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-xl-3 col-md-6 p-2"}>
              <div className={'card'}>
                <div className="card-block">
                  <div className='card-body'>
                    <div className="row">
                      <div className="col-8">
                        <h4>{marks.cat2}</h4>
                        <h6>Daily Habits</h6>
                      </div>
                      <div className="col-4 text-right">
                      </div>
                    </div>
                  </div>

                  <div className={`card-footer ${calsses['bg-teal']}`} onClick={() => handleDataChange(6, "Act of kindness", 4, '#34b3ad ')}>
                    <div className="row align-items-center">
                      <div className="col-9">
                        <span>Update</span>
                      </div>
                      <div className="col-3">
                      <EditIcon/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-xl-3 col-md-6 p-2"}>
              <div className={'card'}>
                <div className="card-block">
                  <div className='card-body'>
                    <div className="row">
                      <div className="col-8">
                        <h4>{marks.cat3}</h4>
                        <h6>Fitness</h6>
                      </div>
                      <div className="col-4 text-right">
                      </div>
                    </div>
                  </div>
                  <div className={`card-footer ${calsses['bg-red']}`} onClick={() => handleDataChange(7, "Fitness", 13, '#e73758')}>
                    <div className="row align-items-center">
                      <div className="col-9">
                        <span>Update</span>
                      </div>
                      <div className="col-3">
                      <EditIcon/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>

          {/* acheivement section */}

          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="row">
                <div className="col-12 no-pd">
                  <div className="main-ws-sec">
                    <div className="post-topbar" style={{ borderTop: `4px solid ${Color}` }}>
                      <div>
                        <span>
                          <h2>{Title}</h2>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* sidebar for cat list */}
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="pd-left-none no-pd">
                <div className="main-left-sidebar no-margin">
                  <div className="user-data full-width">
                    <QuestionCategoriesList id={id} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-8 no-pd">
              <div className="no-pd">
                <div className="main-ws-sec">
                  <div className="post-topbar" style={{ borderTop: `4px solid ${Color}` }}>
                    <Questionaire id={id} cid={Cid} />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ScoreSection