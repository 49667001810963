import React from 'react'
import spin from '../../images/logo-spin.png'
const Loader = () => {
  return (
    <div style={{height:"100vh",width:"100%",position:"absolute",zIndex:"100000",background:"#fff"}}> 
       <div className='spinner-logo' >
            <img src={spin} style={{width:"100px"}}  alt='spin' />
       </div>
    </div>
  )
}

export default Loader