import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { BASE_URL } from '../../Utils/BaseUrl'
import axios from 'axios'
import img from '../../../images/userimg.jpg';

const GroupProfile = () => {
    const [prodata, setProData] = useState([])
    const params = useParams();
    const { groupid } = params

    async function getDatapost() {
        const data = {
            group_id: groupid,
        };
        axios
            .post(`${BASE_URL}/single_group_posts`, data)
            .then((res) => {
                setProData(res.data[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        getDatapost();
    }, [groupid]);


    return (
        <>

                    <div class="user-data full-width">
                        <div class="user-profile">
                            <div class="username-dt">
                                <div class="usr-pic">
                                <img src={prodata.profile_image === '' ? img : 'https://thetalentclub.co.in/upload/group_images/' + prodata.image} alt='' />
                                </div>
                            </div>
                            <div class="user-specs">
                                <h3>{prodata.title}</h3>
                                {/* <span>Graphic Designer at Self Employed</span> */}
                            </div>
                        </div>
                   
                    </div>
           
        </>
    )
}

export default GroupProfile