import CheckIcon from '@mui/icons-material/Check';
import React, { useEffect, useState } from 'react';

import classes from './BucketList.module.css';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';

import CircularProgress from '@mui/material/CircularProgress';
import { BASE_URL } from '../Utils/BaseUrl';

const BucketList = () => {

  const [bucektData, setBucektData] = useState([]);
  const [BucketDOne, setBucketDOne] = useState([]);
  const [Category, setCategory] = useState([]);
  const [value, setValue] = React.useState('1');
  const [Loading, setLoading] = useState(true);
  const [Loading2, setLoading2] = useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [formData, setFormData] = useState({
    id: localStorage.getItem('user_id'),
    Description: '',
    category: '',
    todo: 'to_do'
  });

  const [errors, setErrors] = useState({
    Description: '',
    category: '',
    general: '',
  });
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    setErrors({
      Description: '',
      category: '',
      general: ''
    });

  }

  const isValid = () => {
    let error = { ...errors };

    if (formData.Description === '' && formData.category === '') {
      error.general = 'Both the fields are required.';
    }
    if (formData.Description === '') {
      error.Description = 'Description is required.';
    }

    if (formData.category === '') {
      error.category = 'Please select a category.';
    }
    setErrors(error);
    return Object.values(error).every((error) => error === '');
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValid()) {

      console.log(formData);
      postData();
    }
    setFormData({
      Description: '',
      category: '',
    });

  }
  const getBucekData = async () => {
    const response = await fetch(`${BASE_URL}/getbucketdata`, {
      method: "POST",
      body: JSON.stringify({
        userid: localStorage.getItem('user_id'),
      }),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    const data = await response.json();
    setLoading(false);
    setLoading2(false);
    setBucektData(data);
    // console.log(data);
  }
  const getBucekDone = async () => {
    const response = await fetch(`${BASE_URL}/doneTodo`, {
      method: "POST",
      body: JSON.stringify({
        userid: localStorage.getItem('user_id'),
      }),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    const data = await response.json();
    setBucketDOne(data);
    // console.log(data);
  }
  const postData = async () => {
    const response = await fetch(`${BASE_URL}/todo`, {
      method: "POST",
      body: JSON.stringify({
        user_id: localStorage.getItem('user_id'),
        Description: formData.Description,
        category: formData.category,
        todo: 'to_do',
      }),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    const data = await response.json();
    // window.location.href();
    getBucekData();
    getBucekDone()
    console.log(data);
    // console.log(data);
  }

  const getCategory = async () => {
    const response = await fetch(`${BASE_URL}/getCategory`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      }
    })
    const data = await response.json();
    console.log(data, "category");
    setCategory(data);
  }

  const updateTodo = async (id) => {
    const response = await fetch(`${BASE_URL}/updateTodoDone`, {
      method: "POST",
      body: JSON.stringify({
        todoId: id,
      }),
      headers: {
        'Content-Type': 'application/json',
      }
    })

    const data = await response.json();
    getBucekData();
    getBucekDone();
    console.log(data);
  }
  useEffect(() => {
    getBucekData();
    getCategory();
    getBucekDone();
  }, [])
  return (
    <main>
      <div className='container'>
        <div className="">
          <div className="post-topbar">
            <h1>
              Bucket List
            </h1>
          </div>
          <div className="row gx-5">
            <div className="col-lg-7 col-md-7">
              <div className="pd-left-none no-pd">
                <div className="main-left-sidebar no-margin">
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="To-Do" value="1" />
                        <Tab label="Done" value="2" />
                      </TabList>
                    </Box>
                    {Loading ? (<div className='d-flex align-items-center justify-content-center p-4 mt-2'>
                      <CircularProgress />
                    </div>) : (<TabPanel value="1" sx={{ padding: "24px 0" }}>
                      <div className=" full-width">
                        {bucektData.map((item) => {
                          return (
                            <div className="card mb-2">
                              <div className="cardbody p-2">
                                <div className="row">
                                  <div className="col-11">
                                    <h5>

                                      {item.bucket_title}
                                    </h5>
                                  </div>
                                  <div className="col-1">
                                    <CheckIcon style={{cursor:"pointer"}} onClick={() => updateTodo(item.id)} />
                                  </div>
                                </div>
                                <div>
                                  {item.bucket_category}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </TabPanel>)}
                    {Loading2 ? (<div className='d-flex align-items-center justify-content-center p-4 mt-2'>
                      <CircularProgress />
                    </div>) : (<TabPanel value="2" sx={{ padding: "24px 0" }}>

                      <div className=" full-width">
                        {BucketDOne.map((item) => {
                          return (
                            <div className="card mb-2">
                              <div className="cardbody p-1">
                                <div className="row">
                                  <div className="col-11">
                                    <h5>

                                      {item.bucket_title}
                                    </h5>
                                  </div>
                                  <div className="col-1" >
                                    <CheckIcon style={{ color: "red" }} />
                                  </div>
                                </div>
                                <div>
                                  {item.bucket_category}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </TabPanel>)}
                  </TabContext>
                  {/* <div className=" full-width">
                          {bucektData.map((item)=>{
                            return ( 
                                <div className="card mb-2">
                                    <div className="cardbody p-3">
                                        <div className="row">
                                            <div className="col-11">
                                              <h4>

                                                {item.bucket_title}
                                              </h4>
                                            </div>
                                            <div className="col-1">
                                                <EditIcon/>
                                            </div>
                                        </div>
                                        <div>
                                          {item.bucket_category}
                                        </div>
                                    </div>
                                </div>
                            )
                          })}
                        </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="row">

                <div className="col-lg-12 no-pdd">
                  <div className={classes["sn-field"]}>
                    <input type="text" name="Description" placeholder="Description" onChange={handleInputChange} value={formData.Description} />
                    {/* <i className="la la-user"></i> */}
                    {!errors.general && errors.Description && <p className={classes.error}>{errors.Description}</p>}
                  </div>
                </div>
                <div className="col-lg-12 no-pdd" style={{ marginBottom: "20px" }}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">category</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="category"
                        name="category"
                        value={formData.category}
                        onChange={handleInputChange}
                      >

                        {
                          Category.map((item) => {
                            return (
                              <MenuItem value={item.bucket_category}>{item.bucket_category}</MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  </Box>
                  {!errors.general && errors.category && <p className={classes.error}>{errors.category}</p>}
                  {errors.general && <p className={classes.error}>{errors.general}</p>}
                </div>
                <div className="col-lg-12 no-pdd">
                  <button type="button" class="btn btn-danger full-width" onClick={handleSubmit}>Save</button>

                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
    </main>

  )
}

export default BucketList