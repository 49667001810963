import React from 'react';


import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import getPoints2 from '../../Store/DashboardMarksActions';
import DashBarProgress from './DashSubComponents/DashBarProgress';
import SuggestedGroup from './DashSubComponents/SuggestedGroup';
import YourGroups from './DashSubComponents/YourGroups';
import ProfileDetailCard from './ProfileDetailsComp/ProfileDetailCard';
import RandomUserProfile from './ProfileDetailsComp/RandomUserProfile';
import Footer from './DashSubComponents/Footer';
import ShareHeader from '../Layout/ShareHeader';
import { Link, useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import axios from 'axios'
import { BASE_URL } from '../Utils/BaseUrl';


const ProfileSharePage = () => {
    const [hide, setHide] = useState(false)
    const [prodata, setProData] = useState([])
    const {userid} = useParams()
    async function userdetailsget() {
        const data = {
            user_id: userid,
        };
        axios
            .post(`${BASE_URL}/user_detail_get`, data)
            .then((res) => {
                setProData(res.data[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        userdetailsget();
    }, []);






    return (
        <>
            <ShareHeader />

            <Helmet>
                <title>Talent Club</title>
                <meta name="description" content={`This profile form TheTalentclub`}  />
                <meta name="author" content={`${prodata.firstname}`} />
                <meta property="og:image" content={`https://thetalentclub.co.in/upload/profile/${prodata.profile_image}`} />
            </Helmet>

            <main className={hide ? "overlay" : ""} >
                <div className="main-section">
                    <div className="container">
                        <div className="main-section-data">
                            <div className="row">

                                <div className="col-lg-3 col-md-4 pd-left-none no-pd " id='left-section'>

                                    <div className="main-left-sidebar no-margin">
                                        <RandomUserProfile />



                                    </div>
                                </div>


                                <div className="col-lg-6 col-md-8 no-pd " id='mid-section'>
                                    <div className="main-ws-sec">

                                        <ProfileDetailCard />

                                    </div>
                                </div>


                                <div className="col-lg-3 pd-right-none no-pd " id='right-section'>
                                    <div className="right-sidebar">
                                        {/* <div className="card mb-3 p-2 text-center " style={{ display: 'flex', justifyContent: "center" }} >
                                            <DashBarProgress style={{ marginLeft: "50%" }} />


                                        </div> */}

                                        <Footer />



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default ProfileSharePage;