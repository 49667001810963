import React, { useState } from 'react'
import { BASE_URL } from '../Utils/BaseUrl';
import { md5 } from 'js-md5';

const Settings = () => {
	const [message, setMessage] = useState('')
	const [err, setError] = useState('')
	const [errors, setErrors] = useState({})
	const [passwordValues, setpasswordValues] = useState({
		password: '',
		confirmPassword: '',
	})

	const validateForm = () => {
		let isValid = true;
		const newErrors = {};

		if (!passwordValues.password) {
			isValid = false;
			newErrors.password = "Password is required"
		}
		const passwordPattern = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/;

		if (!passwordPattern.test(passwordValues.password)) {
			isValid = false;
			newErrors.password = "Password requirements: 8-20 characters, 1 number, 1 letter, 1 symbol."
		}

		if (passwordValues.password !== passwordValues.confirmPassword) {
			isValid = false;
			newErrors.cpassword = "Password & Confirm Password dont match"

		}




		setErrors(newErrors);
		setTimeout(() => {
			setErrors("")
		}, 5000);
		return isValid;


	}


	const handlePasswordChange = (event) => {
		const { name, value } = event.target;


		setpasswordValues(prevState => ({
			...prevState,
			[name]: value
		}))
	}


	const handleSubmit = async (event) => {
		event.preventDefault();
		if (validateForm()) {
			const hashPassword = md5(passwordValues.password)
			const chashPassword = md5(passwordValues.confirmPassword)

			const response = await fetch(`${BASE_URL}/updatePassword`, {
				method: "POST",
				body: JSON.stringify({
					id: localStorage.getItem('user_id'),
					password: hashPassword,
					confirmPassword: chashPassword,
				}),
				headers: {
					'Content-Type': "application/json"
				}
			})

			const data = await response.json();
			setMessage(data.message)
			setError(data.error)
		}


	}



	return (
		<div>

			<section className="profile-account-setting">
				<div className="container">
					<div className="account-tabs-setting">

						<div className="tab-content" id="nav-tabContent">
							{<div className={`tab-pane fade show active `} id="nav-password" role="tabpanel" aria-labelledby="nav-password-tab">
								<div className="acc-setting">
									<h3>Change Password</h3>
									<form onSubmit={handleSubmit}>
										<div className="cp-field">
											<h5>New Password</h5>
											<div className="cpp-fiel">
												<input type="password" name="password" value={passwordValues.password} placeholder="New Password" onChange={handlePasswordChange} />
												<i className="fa fa-lock"></i>
											</div>
											<span className='text-danger'>{errors.password}</span>
										</div>
										<div className="cp-field">
											<h5>Repeat Password</h5>
											<div className="cpp-fiel">
												<input type="text" name="confirmPassword" value={passwordValues.confirmPassword} placeholder="Repeat Password" onChange={handlePasswordChange} />
												<i className="fa fa-lock"></i>
											</div>
											<span className='text-danger'>{errors.cpassword}</span>
										</div>
										<div className="cp-field">
											<h5><span href="#" title="">Forgot Password?</span></h5>
										</div>
										<div className="save-stngs pd2">
											<ul>
												<li><button type="submit">Save Setting</button></li>

											</ul>
										</div>
										<span className='text-success'>{message}</span>
										<span className='text-danger'>{err}</span>
									</form>
								</div>
							</div>}


						</div>
					</div>
				</div>
			</section>
		</div>
	)
}

export default Settings