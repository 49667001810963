import { Outlet, createBrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import SignupSIgnIn from './Components/Pages/SignupSIgnIn';
import Dash from './Components/Pages/Dash';
import Header from './Components/Layout/Header'
import Profile from './Components/Pages/Profile';
import MyClub from './Components/Pages/MyClub';
import ProfileDetail from './Components/Pages/ProfileDetailPage';
import SearchPage from './Components/Pages/SearchPage';
import Offer from './Components/Pages/Offer';
import { useEffect, useState } from 'react';
import ClubDetailPage from './Components/Pages/ClubDetailPage';
import Loader from './Components/Utils/Loader';
import Settings from './Components/Pages/Settings';
import ScoreSection from './Components/Pages/ScoreSection';
import { useDispatch } from 'react-redux';

import getPoints2 from './Store/DashboardMarksActions';
import BucketList from './Components/Pages/BucketList';
import ProfileSharePage from './Components/Pages/ProfileSharePage';



const Routing = createBrowserRouter([

  {
    path: "/profileshare/:userid",
    element: <ProfileSharePage />
  },

  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/log',
        element: <SignupSIgnIn />
      },
      {
        path: '/',
        element: <Dash />
      },
      {
        path: '/profile',
        element: <Profile />
      },
      {
        path: '/myclub',
        element: <MyClub />
      },
      {
        path: '/profiledetail/:userid',
        element: <ProfileDetail />
      },
      {
        path: '/clubdetailpage/:groupid',
        element: <ClubDetailPage />
      },
      {
        path: '/searchpage/:desc',
        element: <SearchPage />

      },
      {
        path: '/offer',
        element: <Offer />
      },
      {
        path: '/setting',
        element: <Settings />
      },
      {
        path: '/talentscore',
        element: <ScoreSection />
      },
      {
        path: '/bucketlist',
        element: <BucketList />
      },
    ]
  },

])

function checkLocalStorageAndRedirect(navigate) {
  const id = localStorage.getItem('user_loggedin');
  console.log("User logged in:", id); // Log the value of id
  if (id !== 'true') {
    navigate('/log'); // Redirect to dashboard if id exists in localStorage
  }
}

function App() {
  const { pathname } = useLocation();
  const [loader, setLoader] = useState(true)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0);

  }, [pathname])




  useEffect(() => {

    setLoader(true);

    const timeoutId = setTimeout(() => {
      setLoader(false);
    }, 1000);


    return () => clearTimeout(timeoutId);


  }, [])


  useEffect(() => {
    checkLocalStorageAndRedirect(navigate);
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    if (isMobileDevice) {
      // Redirect to the Play Store URL for mobile users
      window.location.href = 'https://app.thetalentclub.co.in';
    }
  }, [navigate]);
  // const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPoints2())
  })


  return (
    <div className="wrapper" style={{ position: "relative" }}>
      {loader && <Loader />}
      {window.location.pathname !== '/log' && <Header />}
      <Outlet />
    </div>
  );
}

export default Routing;
