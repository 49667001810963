import React, { useEffect } from 'react';

import { useState } from 'react';
import ClubDetailCard from './ClubDetailComponents/ClubDetailCard';
import GroupPostSection from './ClubDetailComponents/GroupPostSection';
import GroupProfile from './ClubDetailComponents/GroupProfile';
import DashBarProgress from './DashSubComponents/DashBarProgress';
import Footer from './DashSubComponents/Footer';
import SuggestedGroup from './DashSubComponents/SuggestedGroup';
import YourGroups from './DashSubComponents/YourGroups';
import axios from 'axios';
import { BASE_URL } from '../Utils/BaseUrl';
import { useParams } from 'react-router-dom';

const ClubDetailPage = () => {
    const [hide, setHide] = useState(false)
    const [groupdata, setgroupData] = useState([]);
    const params = useParams();

    const { groupid } = params

    async function getgroupdata() {
        const data = {
            user_id: localStorage.getItem('user_id'),
        };
        axios
            .post(`${BASE_URL}/group_name`, data)
            .then((res) => {
                setgroupData(res.data);
            })
            .catch((err) => {
                console.log(err);
            })

    }

    useEffect(() => {
        getgroupdata()
    }, [])


    return (
        <>

            <main className={hide ? "overlay" : ""} >
                <div className="main-section">
                    <div className="container">
                        <div className="main-section-data">
                            <div className="row">

                                <div className="col-lg-3 col-md-4 pd-left-none no-pd " id='left-section'>

                                    <div className="main-left-sidebar no-margin">
                                        <GroupProfile />

                                        <SuggestedGroup />

                                        <Footer />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-8 no-pd " id='mid-section'>
                                    <div className="main-ws-sec">

                                        {groupdata?.some((ele) => ele.id == groupid) && <GroupPostSection hide={hide} setHide={setHide} />}
                                        <ClubDetailCard />

                                    </div>
                                </div>


                                <div className="col-lg-3 pd-right-none no-pd " id='right-section'>
                                    <div className="right-sidebar">
                                        <div className="card mb-3 p-2 text-center " style={{ display: 'flex', justifyContent: "center" }} >
                                            <DashBarProgress style={{ marginLeft: "50%" }} />


                                        </div>
                                        <YourGroups />


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    )
}

export default ClubDetailPage;