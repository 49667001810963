import React, { useState } from 'react'
import mainimg from '../../images/signup/cm-main-img.png'
import talentLogo from '../../images/signup/talentlogo.png'
import classes from './SignUpSignIn.module.css'
import { BASE_URL } from '../Utils/BaseUrl'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import md5 from 'js-md5'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
const SignupSIgnIn = () => {
	const [singnIn, setSingnIn] = useState(true);
	const [signup, setSignup] = useState(false);
	const [valid, setValid] = useState([]);
	const [error, setErrors] = useState()
	const handleSignUpClick = () => {
		setSignup(true);
		setSingnIn(false);
	}
	const handleSignInClick = () => {
		setSignup(false);
		setSingnIn(true);
	}

	const [signInValues, setsignInValues] = useState({
		userName: '',
		password: '',
	})

	const [SignUpValues, setSignUpValues] = useState({
		organisationName: '',
		firstName: '',
		lastName: '',
		email: '',
		mobile: '',
		password: '',
		cnfPassword: '',
	})

	const [signInErrors, setsignInErrors] = useState({
		username: '',
		password: '',
	})

	const [signUpErrors, setSignUpErrors] = useState({
		firstName: '',
		lastName: '',
		email: '',
		mobile: '',
		password: '',
		cnfPassword: '',
	})

	const handleSignInChange = (event) => {
		const { name, value } = event.target;
		setsignInValues(prevState => ({
			...prevState,
			[name]: value
		}));
	}

	const hadnleSignUpChange = (event) => {
		const { name, value } = event.target;

		setSignUpValues(prevState => ({
			...prevState,
			[name]: value
		}))
	}

	const validateSignIn = () => {
		let errors = { ...signInErrors };

		if (signInValues.userName === '') {
			errors.username = 'Username is required';
		}

		if (signInValues.password === '') {
			errors.password = 'Password is required';
		}

		setsignInErrors(errors);

		return Object.keys(errors).every(key => errors[key] === '');
	};

	const validateSignUp = () => {
		let errors = { ...signUpErrors };

		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

		if (SignUpValues.firstName === '') {
			errors.firstName = 'First name is required';
		}

		if (SignUpValues.lastName === '') {
			errors.lastName = 'Last name is required';
		}
		if (SignUpValues.email.trim() === '') {
			errors.email = 'Email is required';
		} else if (!emailRegex.test(SignUpValues.email)) {
			errors.email = 'Invalid email format';
		}
		if (SignUpValues.mobile.trim() === '') {
			errors.mobile = 'Mobile number is required';
		} else if (SignUpValues.mobile.trim().length !== 10) {
			errors.mobile = 'Mobile number must be 10 digits';
		} else if (!/^\d+$/.test(SignUpValues.mobile)) {
			errors.mobile = 'Mobile number must contain only digits';
		}

		if (SignUpValues.password.trim() === '') {
			errors.password = 'Password is required';
		} else if (SignUpValues.password.length < 6) {
			errors.password = 'Password must be 6 characters long';
		}
		if (SignUpValues.cnfPassword.trim() === '') {
			errors.cnfPassword = 'confirm password is required';
		} else if (SignUpValues.cnfPassword.length < 6) {
			errors.cnfPassword = 'confirm password must be 6 characters long';
		}

		if (SignUpValues.password.trim() !== SignUpValues.cnfPassword.trim()) {
			errors.cnfPassword = 'Confirm password should be same as Password';
		}
		setSignUpErrors(errors);
		return Object.values(errors).every((error) => error === '');

	}

	const Navigate = useNavigate()
	const signInSubmit = async (event) => {
		event.preventDefault();

		const hashedPassword = md5(signInValues.password);
		console.log(hashedPassword)

		const user = {
			email: signInValues.userName,
			password: hashedPassword,
		}




		if (validateSignIn()) {
			axios
				.post(`${BASE_URL}/login`, user)
				.then((res) => {
					setValid(res.data)

					setTimeout(() => {

						setValid("")
					}, 2000);

					if (res.data.id) {
						localStorage.setItem("user_loggedin", true);
						localStorage.setItem('user_id', res.data.id);
						Navigate('/');

					}




				})
				.catch((err) => console.log(err))



		}


		setTimeout(() => {
			setsignInErrors({
				username: '',
				password: '',
			})
		}, 3000)
	}

	const handleSignUpSubmit = async (event) => {
		event.preventDefault();
		const hashedPassword = md5(SignUpValues.password);
		console.log(hashedPassword)


		const user = {
			firstname: SignUpValues.firstName,
			lastname: SignUpValues.lastName,
			email: SignUpValues.email,
			mobile: SignUpValues.mobile,
			passWord: hashedPassword,

		}

		if (validateSignUp()) {

			axios.post(`${BASE_URL}/register`, user)
				.then((res) => {
					if (res.data.insertId) {
						document.getElementById("alert").style.display = "block"
					}
					if (res.data.errno == 1062) {
						setErrors('Emailid is Already Taken')
					}

					setTimeout(() => {
						setErrors("")
					}, 4000);

				})
				.catch((err) => {
					console.log(err)
				})


			setSignUpValues({
				organisationName: '',
				firstName: '',
				lastName: '',
				email: '',
				mobile: '',
				password: '',
				cnfPassword: '',
			})
		}
		setTimeout(() => {
			setSignUpErrors({
				firstName: '',
				lastName: '',
				email: '',
				mobile: '',
				password: '',
				cnfPassword: '',
			})
		}, 3000)
	}
	return (
		<div className="wrapper">

			<div className="sign-in-page">
				<div className="signin-popup">
					<div className="signin-pop">
						<div className="row">
							<div className="col-lg-6">
								<div className="cmp-info">
									<div className="cm-logo">
										<img src={talentLogo} alt="" />
										<p>Workwise,  is a global freelancing platform and social networking where businesses and independent professionals connect and collaborate remotely</p>
									</div>
									<img src={mainimg} alt="" />
								</div>
							</div>
							<div className="col-lg-6">
								<div className="login-sec">
									<ul className="sign-control">
										<li onClick={handleSignInClick} data-tab="tab-1" className={`${singnIn ? 'current' : ''} animated fadeIn`}><span>Sign in</span></li>
										<li onClick={handleSignUpClick} data-tab="tab-2" className={`${signup ? 'current' : ''} animated fadeIn`}><span>Sign up</span></li>
									</ul>
									<div className={`${singnIn ? 'current' : ''} sign_in_sec`} id="tab-1">

										{singnIn && <>

											<h3>Sign in</h3>
											<form id='signInForm' onSubmit={signInSubmit}>
												<div className="row">
													<div className="col-lg-12 no-pdd">
														<div className="sn-field">
															<input type="text" name="userName" placeholder="Username" value={signInValues.userName} onChange={handleSignInChange} />
															<i className="la la-user"></i>
															{signInErrors.username && <p className={classes.error}>{signInErrors.username}</p>}
														</div>
													</div>
													<div className="col-lg-12 no-pdd">
														<div className="sn-field">
															<input type="password" name="password" placeholder="Password" value={signInValues.password} onChange={handleSignInChange} />
															<i className="la la-lock"></i>
															{signInErrors.password && <p className={classes.error}>{signInErrors.password}</p>}
														</div>
													</div>
													<div className="col-lg-12 no-pdd">
														<div className="checky-sec">
															<div className="fgt-sec">
																<input type="checkbox" name="cc" id="c1" />
																<label for="c1">
																	<span></span>
																</label>
																<small>Remember me</small>
															</div>
															{/* <a href="google" title="">Forgot Password?</a> */}
														</div>
													</div>
													<div className="col-lg-12 no-pdd">
														<button type="submit" value="submit" form='signInForm'>Sign in</button>
													</div>
													<span style={{ color: "red" }}>{valid}</span>
												</div>
											</form>
										</>}
									</div>
									{signup && <div className={`${signup ? 'current' : ''} sign_in_sec`} id="tab-2">
										<div className="dff-tab current" id="tab-3">
											<h3>Sign up</h3>

											<form onSubmit={handleSignUpSubmit}>
												<div className="row">

													<div className="col-lg-12 no-pdd">
														<div className="sn-field">
															<input type="text" name="firstName" placeholder="First Name" value={SignUpValues.firstName} onChange={hadnleSignUpChange} />
															<i className="la la-user"></i>
															{signUpErrors.firstName && <p className={classes.error}>{signUpErrors.firstName}</p>}
														</div>
													</div>
													<div className="col-lg-12 no-pdd">
														<div className="sn-field">
															<input type="text" name="lastName" placeholder="Last Name" value={SignUpValues.lastName} onChange={hadnleSignUpChange} />
															<i className="la la-user"></i>
															{signUpErrors.lastName && <p className={classes.error}>{signUpErrors.lastName}</p>}
														</div>
													</div>
													<div className="col-lg-12 no-pdd">
														<div className="sn-field">
															<input type="email" name="email" placeholder="Email" value={SignUpValues.email} onChange={hadnleSignUpChange} />
															<i className="la la-user"></i>
															{signUpErrors.email && <p className={classes.error}>{signUpErrors.email}</p>}
														</div>
													</div>
													<div className="col-lg-12 no-pdd">
														<div className="sn-field">
															<input type="text" name="mobile" placeholder="Mobile" value={SignUpValues.mobile} onChange={hadnleSignUpChange} />
															<i className="la la-user"></i>
															{signUpErrors.mobile && <p className={classes.error}>{signUpErrors.mobile}</p>}
														</div>
													</div>

													<div className="col-lg-12 no-pdd">
														<div className="sn-field">
															<input type="password" name="password" placeholder="Password" value={SignUpValues.password} onChange={hadnleSignUpChange} />
															<i className="la la-lock"></i>
															{signUpErrors.password && <p className={classes.error}>{signUpErrors.password}</p>}
														</div>
													</div>
													<div className="col-lg-12 no-pdd">
														<div className="sn-field">
															<input type="password" name="cnfPassword" placeholder="Repeat Password" value={SignUpValues.cnfPassword} onChange={hadnleSignUpChange} />
															<i className="la la-lock"></i>
															{signUpErrors.cnfPassword && <p className={classes.error}>{signUpErrors.cnfPassword}</p>}
														</div>
													</div>
													{/* <div className="col-lg-12 no-pdd">
														<div className="checky-sec st2">
															<div className="fgt-sec">
																<input type="checkbox" name="cc" id="c2" />
																<label for="c2">
																	<span></span>
																</label>
																<small>Yes, I understand and agree to the workwise Terms & Conditions.</small>
															</div>
														</div>
													</div> */}

													<div className="col-lg-12 no-pdd">
														<button type="submit" value="submit">Sign Up</button>
													</div>
													<span className='text-danger'>{error}</span>
													<Stack sx={{ width: '100%', display: "none" }} id="alert" className='mt-3' spacing={1}>
														<Alert variant="outlined" severity="success">
															Account Created Succesfully Please go <a href='/'><span style={{ textDecoration: "underline" }}>back to login</span></a>
														</Alert>
													</Stack>
												</div>
											</form>
										</div>
									</div>}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footy-sec">
					<div className="container">
						<ul>
							<li><a href="help-center.html" title="">Help Center</a></li>
							<li><a href="about.html" title="">About</a></li>
							<li><a href="#" title="">Privacy Policy</a></li>
							<li><a href="#" title="">Community Guidelines</a></li>
							<li><a href="#" title="">Cookies Policy</a></li>
							<li><a href="#" title="">Career</a></li>
							<li><a href="forum.html" title="">Forum</a></li>
							<li><a href="#" title="">Language</a></li>
							<li><a href="#" title="">Copyright Policy</a></li>
						</ul>
						<p><img src="images/copy-icon.png" alt="" />Copyright 2019</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SignupSIgnIn